<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto">
      <div class="overflow-auto w-full">
        <div v-if="!community" class="mt-3 border border-blue-400 font-400 text-sm text-blue-800 font-frank px-4 py-6 bg-blue-200 text-center">
            <p>please select a community to continue.</p>
        </div>
        <div v-else>
          <List
            resource="allGuest"
            :fields="fields"
            :pageSize="18"
            trackBy="id_guest"
            :search="true"
            :clickable="true"
            :isCommunityRequired="false"
            @deleteRow="handleView"
            :requestParams="{sortField: '', direction: ''}"
          >
            <template  v-slot:status>
              <div class="font-semibold font-inter p-4 text-blue-700 cursor-pointer">
                view
              </div>
            </template>
          </List>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import List from '@/components/digital_human/List'

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    Loader,
    List
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          name: 'full_name',
          title: 'name',
          sortField: 'full_name'
        },
        {
          name: 'email',
          title: 'email',
          sortField: 'email'
        },
        {
          name: 'phone_number',
          title: 'phone number',
          sortField: 'phone_number'
        },
        {
          name: 'status',
          title: 'conversation'
        }
      ],
    }
  },
  methods: {
    handleView(row) {
      const id = row['data'].id_guest;
      this.$router.push({name: 'guest_contacts.details', params: {id}});
    }
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
      return;
    }
  },
}
</script>
