<template>
  <div class="list-container">
    <loader :loading="loading" :backdrop="true"/>
    <div
      v-if="search"
      class="flex justify-between items-center search-container mb-8"
    >
      <div class="p-4 mr-8 w-full">
        <slot name="message"></slot>
      </div>
      <div class="flex w-5/12">
        <text-input
          v-if="search"
          :placeholder="searchPlaceholder"
          v-model="query"
          @keyup="onSearch"
          @keyup.enter="doSearch"
          :disabled="isSelected"
        />
        <button class="btn-primary ml-4" v-if="searchButton" @click="doSearch">Search</button>
      </div>
    </div>
    <div v-if="isEmpty" class="font-semibold font-inter p-4 bg-blue-50 text-blue-700 mr-8">
      no data available
    </div>
    <div v-else>
      <vuetable v-if="clickable"
        class="list"
        ref="vuetable"
        :api-mode="false"
        :fields="fields"
        :data-manager="dataManager"
        :css="css.table"
        :track-by="trackBy"
        :sort-order="isSelected ? [] : sortOrder"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
        :row-class="rowClass"
        @vuetable:cell-clicked="returnRowData"
      >
        <template v-slot:status="props">
          <div @click="handleDelete(props)" v-if="hasIcons">
            <slot name="status" :status="props.rowData.status"/>
          </div>
        </template>
      </vuetable>
      <vuetable v-else
        ref="vuetable"
        :api-mode="false"
        :fields="fields.filter((field) => field.name !== 'status')"
        :data-manager="dataManager"
        :css="css.table"
        :track-by="trackBy"
        :sort-order="isSelected ? [] : sortOrder"
        pagination-path="pagination"
        @vuetable:pagination-data="onPaginationData"
      />
      <vuetable-pagination
        ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import {Vuetable, VuetablePagination} from 'vue3-vuetable';
import {throttle} from 'lodash-es';
import Loader from '@/components/ui/Loader';
import NotifyMixin from "@/mixins/NotifyMixin";
import AuthMixin from '@/components/auth/AuthMixin';
import TextInput from '@/components/ui/TextInput';
const css = {
  table: {
    tableClass: 'table-auto w-full',
    tableBodyClass: '',
    tableHeaderClass: 'px-4 py-2',
    tableWrapper: '',
    loadingClass: 'loading',
    ascendingIcon: 'blue chevron up icon',
    descendingIcon: 'blue chevron down icon',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },
  pagination: {
    wrapperClass: 'flex justify-center py-4',
    activeClass: 'active',
    disabledClass: '',
    pageClass: 'btn-paging',
    linkClass: 'btn-paging',
    paginationClass: '',
    paginationInfoClass: 'pagination-info',
    dropdownClass: '',
    icons: {
      first: '',
      prev: '',
      next: '',
      last: '',
    }
  },
};
export default {
  name: 'List',
  data: function () {
    return {
      query: '',
      loading: false,
      selectedIndex: null,
      isDisabled: false
    };
  },
  computed: {
    hasIcons() {
      return !!this.$slots.status;
    },
    isEmpty() {
      if(!this.search) return false;
      if(!this.query) return false;
      if(this.query.trim().length !== 0) return false;
      return this.query.split(' ').length - 1 >= 2 && !this.$props.searchButton
    }
  },
  props: {
    css: {
      type: Object,
      default() {
        return css;
      },
    },
    fields: {
      type: Array,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    sortOrder: {
      type: Array
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    requestParams: {
      type: Object,
      default() {
        return {};
      }
    },
    search: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Start typing to search'
    },
    searchButton: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isCommunityRequired: {
      type: Boolean,
      default: false
    },
     onReload: {
      type: Function,
      default: function () {}
    },
  },
  emits: ['deleteRow', 'rowData'],
  components: {
    Loader,
    Vuetable,
    VuetablePagination,
    TextInput,
  },
  mixins: [NotifyMixin, AuthMixin],
  watch: {
    onReload() {
      this.reload();
    },
    community: function () {
      if (this.community?.id && this.$props.isCommunityRequired) {
        this.dataManager();
        this.$refs.vuetable.refresh();
      } else if (!this.$props.isCommunityRequired) {
        this.dataManager();
        this.$refs.vuetable.refresh();
      } else {
        if(this.$refs.vuetable) {
          this.$refs.vuetable.resetData();
          this.$refs.vuetable.refresh();
        }
      }
    }
  },
  methods: {
    handleDelete(payload) {
      if(this.isSelected) {
        return;
      }
      const { rowIndex, rowData } = payload;
       const paginationData = {
        data: rowData,
        index: rowIndex
      }
      this.selectedIndex = rowIndex;
      this.$emit('deleteRow', paginationData)
    },
    doSearch: throttle(function () {
      if (this.query.length >= 1 || this.query.length === 0) {
        this.reload();
      }
    }, 500, {trailing: true}),
    reload() {
      if(this.$refs.vuetable) {
        this.$refs.vuetable.resetData();
        this.$refs.vuetable.refresh();
      }
    },
    onSearch() {
      if(!this.$props.searchButton) {
        this.doSearch();
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination?.setPaginationData(paginationData);
    },
    onChangePage(page) {
      if(this.isSelected) {
        return;
      }
      this.$refs.vuetable.changePage(page);
    },
    addHighlightTag(row, query, highlightedColumns) {
      if(!query) return row;
      const queryLowC = query.toLowerCase();
      return Object.entries(row).reduce((prevVal, currentVal) => {
        const [key, value] = currentVal;
        if(highlightedColumns.includes(key)) {
          if(typeof value === 'string') {
            return { ...prevVal, [key]: value.toLowerCase().replace(queryLowC, `<mark>${queryLowC}</mark>`) }
          }
          if(Array.isArray(value)) {
            return { ...prevVal, [key]: value.map(val => val.toLowerCase().replace(queryLowC, `<mark>${queryLowC}</mark>`)) }
          }
        }
        return { ...prevVal, [key]: value }
      }, {});
    },
    dataManager(sortOrder = [], pagination = {current_page: 1}) {
      if (!this.community?.id && this.$props.isCommunityRequired) return;
      const {pageSize: size, query} = this;
      const sort = sortOrder[0] ? sortOrder[0] : { direction: '', sortField: ''};
      const highlightedColumns = this.$props.fields.filter(field => field.highlight).map(field => field.name);
      this.loading = true;
      return this.$dhDataProvider.getList(
        this.resource,
        {
          communityId: this.community?.id,
          page: pagination.current_page,
          limit: size,
          search: query,
          order: sort.direction,
          name: sort.sortField,
          ...this.requestParams,
          ...sort,
        })
        .then((res) => {
          const newPagination = this.$refs.vuetable?.makePagination(res?.totalElements, this.pageSize, pagination.current_page);
          const data = res?.data || res;
          return {
            pagination: newPagination,
            data: data.map((row) => this.addHighlightTag(row, query, highlightedColumns)),
            sort
          };
        })
        .catch(error => {
          this.notifyError(error.message);
          return {
            pagination: this.$refs.vuetable?.tablePagination,
            data: this.$refs.vuetable?.tableData
          };
        })
        .finally(() => this.loading = false);
    },
    handleScrollContainer(e) {
      if (this.$refs.vuetable.tablePagination && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 2) {
        this.onChangePage('next');
      }
    },
    returnRowData(paginationData){
      if(this.isSelected) {
        return;
      }
      this.selectedIndex = paginationData.index;
      this.$emit('rowData', paginationData)
    },
    rowClass(_, index) {
      if(!this.isSelected) {
        return ''
      }
      return this.selectedIndex === index ? 'row--active' : 'row--disabled';
    },
  },
  mounted() {
    this.$refs.vuetable.changePage(1);
  },
};
</script>
<style scoped>
.search-container {
  height: fit-content;
}
.list-container {
  position: relative;
}
:deep(.list) .row--active > td {
  @apply bg-highlight-100 text-highlight-500;
}
:deep(.list) .row--disabled > td {
  @apply bg-gray-50 text-gray-400;
}
</style>
